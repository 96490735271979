import * as types from "./../store/types";
import { mapActions } from "vuex";
import authService from "../services/authService";

const jalaali = require("jalaali-js");
export default {
    data() {
        return {
            registerData: {
                name: "",
                email: "",
                password: "",
                mobile: "",
                national_code: "",
                category_id: 1,
                category_name: "",
                business_name: "",
                slug: "",
                logo: "",
                address: "",
                postal_code: "",
                is_legal: 0,
                business_national_id: "",
                registration_number: "",
                date: "",
                year: "",
                month: "",
                day: "",
                ceo_name: "",
            },
                successRegister:false,
            registerResponse: "",
        };
    },
    methods: {
        ...mapActions({
            categoriesAction: types.ACTION_CATEGORIES,
            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
        }),
        getCategories() {
            authService
                .getRegisterCategories()
                .then((response) => {
                    this.categoriesAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        setCategories(id, name) {
            this.registerData.category_id = id;
            this.registerData.category_name = name;
        },
        setIsLegal(id) {
            this.registerData.is_legal = id;
            // this.registerData.name = "";
            // this.registerData.email = "";
            // this.registerData.password = "";
            // this.registerData.mobile = "";
            // this.registerData.national_code = "";
            this.registerData.category_id = 1;
            this.registerData.category_name = "";
            this.registerData.business_name = "";
            this.registerData.slug = "";
            // this.registerData.logo = "";
            this.registerData.address = "";
            this.registerData.postal_code = "";
            // this.registerData.is_legal = 0;
            this.registerData.business_national_id = "";
            this.registerData.registration_number = "";
            this.registerData.date = "";
            this.registerData.year = "";
            this.registerData.month = "";
            this.registerData.day = "";
            this.registerData.ceo_name = "";

        },
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                this.previewImage = e.target.result;
                this.registerData.logo = this.previewImage;
            };
        },
        submit() {
            this.$bvToast.hide();
            this.loading = true;
            authService
                .register(this.registerData)
                .then(() => {
                    this.successRegister=true;
                    this.$bvToast.toast(

                        this.$i18n.t("toast.successfullRegistration"),
                        {
                            title: this.$i18n.t("toast.successTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "successFontColor rtl text-right font-weight-bold py-3",
                            headerClass:
                                "successBackgroundColor successFontColor  rtl text-right",
                            solid: false,
                        }
                    );
                    this.$router.push({name: "login"});

                })
                .catch((error) => {
                    // if (error.response.data.data.code === "validation_error") {
                    if (error.response.data.data.errors) {
                        const keys = Object.keys(error.response.data.data.errors);
                        keys.forEach((key) => {
                            this.$bvToast.toast(
                                error.response.data.data.errors[key],
                                {
                                    title: this.$i18n.t("toast.errorTitle"),
                                    noCloseButton: true,
                                    variant: "custom",
                                    bodyClass:
                                        "dangerBackgroundColor dangerFontColor rtl text-right",
                                    headerClass:
                                        "dangerBackgroundColor dangerFontColor  rtl text-right",
                                    solid: false,
                                }
                            );
                        });
                    }
                    else {
                        this.$bvToast.toast(error.response.data.data.message, {
                            title: this.$i18n.t("toast.errorTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "dangerBackgroundColor dangerFontColor rtl text-right",
                            headerClass:
                                "dangerBackgroundColor dangerFontColor  rtl text-right",
                            solid: false,
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    // console.log("fanaly");
                    /*localStorage.setItem( "accessToken" , response.data.token );
                     localStorage.setItem( "isAuth" , true );
                     this.checkIsAuth();*/



                });
        },
        onSubmit(evt) {
            evt.preventDefault();
        },
        onReset(evt) {
            evt.preventDefault();
            this.registerData = {};
        },
        checkForm(e) {
            this.$bvToast.hide();
            this.loading = true;
            if (this.registerData.year !== "" && this.registerData.month !== "" && this.registerData.day !== "") {
                this.registerData.date = jalaali.toGregorian(
                    parseInt(this.registerData.year),
                    parseInt(this.registerData.month),
                    parseInt(this.registerData.day)
                );
            }
            if (this.registerData.name !== "" && this.registerData.email !== "" && this.registerData.username !== "" && this.registerData.password !== "") {
                if (this.registerData.password.length < 8) {
                    this.$bvToast.toast(this.$i18n.t("toast.invalidPassword"), {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                    this.loading = false;
                }
                else {
                    this.submit();
                    e.preventDefault();
                }
            }
            else {
                this.$bvToast.toast(
                    this.$i18n.t("toast.emptyFieldsError"),
                    {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    }
                );
                this.loading = false;
            }
        },
    },
};
