import * as types from "./../../store/types";
import { mapGetters } from "vuex";
import $registerModule from "./../../mixins/registerModule";
const loginComp = () => import("@/components/loginComp/index.vue");

export default {
  name: "register",
  components: { loginComp },
  mixins: [$registerModule],
  props: [],
  data() {
    return {
      show: true,
      validate: {
        isvalid: true,
        message: "شماره موبایل به درستی وارد نشده است"
      },
      loading: false,
      previewImage: null,
      keyword: ""
    };
  },
  watch: {
    "$route"() {
      this.getCategories();
    }
  },
  computed: {
    ...mapGetters({
      categories: types.GET_CATEGORIES,
      infoForRegisterFlag: types.GET_INFO_FOR_REGISTER_FLAG,
      infoForRegister: types.GET_INFO_FOR_REGISTER
    }),
    setUserInfo() {
      if (this.infoForRegister !== "") {
        this.registerData.name = this.infoForRegister.name;
        this.registerData.email = this.infoForRegister.email;
        this.registerData.username = this.infoForRegister.username;
        this.registerData.mobile = this.infoForRegister.mobile;
        this.registerData.national_code = this.infoForRegister.national_id;
      }
    },
    filteredCategories() {
      if (this.categories && this.categories.length) {
        return this.categories.filter(category => {
          return category.name.includes(this.keyword);
        });
      }
    }
  },
  mounted() {},
  created() {
    this.getCategories();
  },
  methods: {
    openModal() {
      this.$refs.modal.show();
    },
    hideModal() {
      this.$refs.modal.hide();
    }
  }
};
